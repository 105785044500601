/*Font*/
@font-face {
  font-family: "NanumGothic";
  src: url("./assets/Fonts/NanumGothic.otf") format("otf"),
    url("./assets/Fonts/NanumGothic.woff") format("woff");
}

@font-face {
  font-family: "NanumGothicBold";
  src: url("./assets/Fonts/NanumGothicBold.otf") format("otf");
}

@font-face {
  font-family: "OpenSansBold";
  src: url("./assets/Fonts/OpenSans-Semibold.ttf") format("ttf"),
    url("./assets/Fonts/OpenSans-Semibold.woff") format("woff");
}

@font-face {
  font-family: "OpenSans";
  src: url("./assets/Fonts/OpenSans-Regular.ttf") format("ttf"),
    url("./assets/Fonts/OpenSans-Regular.woff") format("woff");
}

@font-face {
  font-family: "MontserratBold";
  src: url("./assets/Fonts/Montserrat-Bold.ttf") format("ttf"),
    url("./assets/Fonts/Montserrat-Bold.woff") format("woff");
}

@font-face {
  font-family: "MontserratRegular";
  src: url("./assets/Fonts/Montserrat-Regular.ttf") format("ttf"),
    url("./assets/Fonts/Montserrat-Regular.woff") format("woff");
}

@font-face {
  font-family: "SpoqaHanSansRegular";
  src: url("./assets/Fonts/SpoqaHanSansRegular.ttf") format("ttf"),
    url("./assets/Fonts/SpoqaHanSansRegular.woff") format("woff");
}
